import {setToken} from './auth.js';

const INFO_KEY = 'bookgm_info'

export const getInfo = () => {
    const defaultObj = {
        userid:'',
        username:'',
        isAdmin:'',
        token:''
    }
    const res = localStorage.getItem(INFO_KEY)
    return res?JSON.parse(res):defaultObj
}
export const setInfo = (Obj) => {
    localStorage.setItem(INFO_KEY,JSON.stringify(Obj))
    setToken(Obj.token)
}
export const removeInfo = () => {
    localStorage.removeItem(INFO_KEY)
}