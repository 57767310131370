import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/404',
    component: () => import('@/views/error/404')
  },
  {
    path: '/',
    component: () => import('@/views/login'),
    meta:{ requiresAuth: false },
  },
  {
    path: '/register',
    component: () => import('@/views/register'),
    meta:{ requiresAuth: false },
  },
  {
    path: '/index',
    component: () => import('@/views/index'),
    // requiresAuth: true 给当前路由一个标签信息，作用需要有权限才能使用该路由
		meta:{ requiresAuth: true },
    children: [
      {
        path: '/bookinfo',
        component: () => import('@/views/book/bookinfo'),
        meta:{ requiresAuth: true },
      },
      {
        path: '/booktype',
        component: () => import('@/views/book/booktype'),
        meta:{ requiresAuth: true },
      },
      {
        path: '/bookborrow',
        component: () => import('@/views/book/bookborrow'),
        meta:{ requiresAuth: true },
      },
      {
        path: '/index',
        component: () => import('@/views/index_v1'),
        meta:{ requiresAuth: true },
      },
      {
        path: '/user',
        component: () => import('@/views/system/user'),
        meta:{ requiresAuth: true },
      },
      {
        path: '/passwd',
        component: () => import('@/views/system/passwd'),
        meta:{ requiresAuth: true },
      },
      {
        path: '/tmp',
        component: () => import('@/views/tem'),
        meta:{ requiresAuth: true },
      },
      {
        path: '/temp',
        component: () => import('@/views/temp/index'),
        meta:{ requiresAuth: true },
      },
    ]
  },
]

const router = new VueRouter({
  routes
})

export default router
