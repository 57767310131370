
function decodeJwtToken(token) {
    // 分解JWT的三个部分
    const parts = token.split('.');
    
    if (parts.length !== 3) {
        console.error('JWT token异常');
        return;
    }
    
    const [headerBase64, payloadBase64, signature] = parts;
    
    // 解码Base64格式的头部和载荷
    const header = JSON.parse(atob(headerBase64));
    const payload = JSON.parse(atob(payloadBase64));
    // console.log('JWT token解析结果：', { header, payload, signature });
    return {
        header,
        payload,
        signature
    };
}

import {getInfo,setInfo} from '@/utils/storage'

export default {
    namespaced:true,
    state(){
        return{
            user:getInfo()
        }
    },
    mutations:{
        setUser(state,user){
            state.user.token = user
            const decodedToken = decodeJwtToken(user);
            state.user.userid = decodedToken.payload.userid;
            state.user.username = decodedToken.payload.username;
            state.user.isAdmin = decodedToken.payload.isAdmin;
            setInfo(state.user)
        }
    },
    actions:{
        setUser(context,user){
            context.commit('setUser',user)
        }
    },
    getters:{
        user(state){
            return state.user
        }
    }
}